.rating {
  @apply flex items-center flex-row-reverse w-max;

  @media(hover) {

    &-star:hover,
    &-star:hover~&-star {
      @apply text-yellow;
    }
  }

  &-star {
    @apply text-gray text-24 cursor-pointer duration-2;

    &~& {
      @apply mr-3;
    }
  }

  &-input:checked~&-star {
    @apply text-yellow;
  }
}