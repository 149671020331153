// Шапка документа
.header {
  @screen md {
    transform: translateY(var(--top));
  }
}

// Меню
.menu {
  &[data-sidebar="open"] {
    @apply visible opacity-100;
  }

  &[data-sidebar="open"] &__content {
    transform: translateX(0);

    @apply visible opacity-100;
  }

  &[data-sidebar="open"] &__head {
    transform: translateY(0);
  }

  &[data-sidebar="open"] &__nav {
    transform: translateX(0);

    @apply visible opacity-100;
  }

  &[data-sidebar="open"] &__footer {
    transform: translateY(0);

    @apply visible opacity-100;
  }

  &__content {
    box-shadow: 0 0 0 100vw theme("colors.black.DEFAULT / 50%");
    transform : translateX(-100%);
  }

  &__head {
    transform: translateY(-80px);
  }

  &__nav {
    transform: translateX(-220px);
  }

  &__footer {
    transform: translateY(-50px);
  }
}

// Главный блок
.main {
  &-slide:not(.swiper-slide-active) &__item {
    @screen sm {
      @apply translate-y-5 opacity-0;
    }
  }
}

// Каталог
.catalog {
  &__wildberries {
    background: linear-gradient(90deg, #CB11AB 8.33%, #909 50.74%, #481173 91.67%);
  }

  &__ozon {
    background: linear-gradient(#005BFF, #005BFF);
  }

  &__letual {
    background: linear-gradient(#EB1D85, #EB1D85);
  }

  &__accordion[data-accordion="active"] &__arrow {
    transform: rotate(180deg);
  }
}

// Обратная связь
.feedback {
  background-image: linear-gradient(90deg, #B9DE99 0%, #A0C882 45.83%, #65944D 100%);

  &__flower {
    animation: progress 30s linear infinite;
  }
}