@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import "@fancyapps/ui/dist/fancybox/fancybox.css";
  @import "swiper/swiper-bundle.min.css";
  @import "./ui-kit/fonts";
  @import "./ui-kit/basic";
  @import "./ui-kit/scrollbar";
  @import "./ui-kit/custom";
  @import "./ui-kit/waved";
  @import "./ui-kit/movement";
}

@layer components {
  @import "./ui-kit/load";
  @import "./ui-kit/rating";
}

@import "./front-end/main";